<template>
  <div>
    <fabric-material-edit
      v-if="isFabric(categoryType)"
      :category-type="categoryType"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <label-material-edit
      v-if="categoryType === 'label'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <button-material-edit
      v-if="categoryType === 'button'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <drawcord-material-edit
      v-if="categoryType === 'drawcord'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <elastic-material-edit
      v-if="categoryType === 'elastic'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <hangtag-material-edit
      v-if="categoryType === 'hangtag'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <metal-eyelet-material-edit
      v-if="categoryType === 'metal-eyelet'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <metal-snap-material-edit
      v-if="categoryType === 'metal-snap'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <tape-material-edit
      v-if="categoryType === 'tape'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <zipper-material-edit
      v-if="categoryType === 'zipper'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <box-material-edit
      v-if="categoryType === 'box'"
      :section="section"
      :init-data="{...initData, color_details: {} }"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <bag-material-edit
      v-if="categoryType === 'bag'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <tapes-material-edit
      v-if="categoryType === 'tapes'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <stickers-material-edit
      v-if="categoryType === 'stickers'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <postcards-material-edit
      v-if="categoryType === 'postcards'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
    <paper-material-edit
      v-if="categoryType === 'paper'"
      :section="section"
      :init-data="initData"
      :style-media="styleMedia"
      :color-options="colorOptions"
      :supplier-options="filteredSupplierOptions"
      :submit-handler="submitHandler"
      :fetch-style-material-media="fetchStyleMaterialMedia"
    />
  </div>
</template>
<script>
import {
  computed,
} from '@vue/composition-api'

import BagMaterialEdit from './BagMaterialEdit.vue'
import BoxMaterialEdit from './BoxMaterialEdit.vue'
import ButtonMaterialEdit from './ButtonMaterialEdit.vue'
import DrawcordMaterialEdit from './DrawcordMaterialEdit.vue'
import ElasticMaterialEdit from './ElasticMaterialEdit.vue'
import FabricMaterialEdit from './FabricMaterialEdit.vue'
import HangtagMaterialEdit from './HangtagMaterialEdit.vue'
import LabelMaterialEdit from './LabelMaterialEdit.vue'
import MetalEyeletMaterialEdit from './MetalEyeletMaterialEdit.vue'
import MetalSnapMaterialEdit from './MetalSnapMaterialEdit.vue'
import PaperMaterialEdit from './PaperMaterialEdit.vue'
import PostcardsMaterialEdit from './PostcardsMaterialEdit.vue'
import StickersMaterialEdit from './StickersMaterialEdit.vue'
import TapeMaterialEdit from './TapeMaterialEdit.vue'
import TapesMaterialEdit from './TapesMaterialEdit.vue'
import ZipperMaterialEdit from './ZipperMaterialEdit.vue'

export default {
  components: {
    BagMaterialEdit,
    BoxMaterialEdit,
    ButtonMaterialEdit,
    DrawcordMaterialEdit,
    ElasticMaterialEdit,
    FabricMaterialEdit,
    HangtagMaterialEdit,
    LabelMaterialEdit,
    MetalEyeletMaterialEdit,
    MetalSnapMaterialEdit,
    PaperMaterialEdit,
    PostcardsMaterialEdit,
    StickersMaterialEdit,
    TapeMaterialEdit,
    TapesMaterialEdit,
    ZipperMaterialEdit,
  },
  setup(props) {
    const filteredSupplierOptions = computed(() => props.supplierOptions
      .filter(supplier => supplier.category_types.includes(props.categoryType)))

    const fabricCategoryTypes = [
      'main-fabric',
      'second-fabric',
      'trims-fabric',
      'lining',
      'rib',
    ]
    const isFabric = category => fabricCategoryTypes.includes(category)

    return {
      filteredSupplierOptions,
      isFabric,
    }
  },
  props: {
    colorOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    supplierOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    categoryType: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },
    styleMedia: {
      type: Array,
      required: false,
      default: () => [],
    },
    fetchStyleMaterialMedia: {
      type: Function,
      required: false,
      default: null,
    },
    initData: {
      type: Object,
      required: false,
      default: () => ({
        specifics: {}, dimensions: {}, consumption: {},
      }),
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select .vs__deselect {
  background: #d71a1c;
  fill: #fff;
}
</style>
