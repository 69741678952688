<template>

  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >

      <template
        v-if="section === 'specifics'"
      >
        <!-- Quality -->
        <validation-provider
          #default="validationContext"
          name="Quality"
          rules="required"
        >
          <b-form-group
            label="Quality *"
            label-for="quality"
            :state="getValidationState(validationContext)"
          >
            <b-form-select
              v-model="materialData.specifics.quality"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="qualityOptions"
              input-id="quality"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </template>

      <template
        v-if="section === 'dimensions'"
      >
        <!-- Standard -->
        <b-form-group
          label="Standard"
          label-for="standard"
        >
          <b-form-select
            v-model="materialData.dimensions.standard"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="standardOptions"
            input-id="standard"
          />
        </b-form-group>

        <!-- Length -->
        <b-form-group
          label="Custom Length"
          label-for="custom-length"
        >
          <b-input-group>
            <b-form-input
              id="custom-length"
              v-model="materialData.dimensions.custom_length"
              trim
            />
            <b-input-group-append>
              <b-form-select
                v-model="materialData.dimensions.custom_length_units"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dimensionOptions"
                input-id="length-units"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <!-- Width -->
        <b-form-group
          label="Custom Width"
          label-for="custom-width"
        >
          <b-input-group>
            <b-form-input
              id="custom-width"
              v-model="materialData.dimensions.custom_width"
              trim
            />
            <b-input-group-append>
              <b-form-select
                v-model="materialData.dimensions.custom_width_units"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dimensionOptions"
                input-id="width-units"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <!-- Height -->
        <b-form-group
          label="Custom Height"
          label-for="custom-height"
        >
          <b-input-group>
            <b-form-input
              id="custom-height"
              v-model="materialData.dimensions.custom_height"
              trim
            />
            <b-input-group-append>
              <b-form-select
                v-model="materialData.dimensions.custom_height_units"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dimensionOptions"
                input-id="height-units"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </template>

      <template
        v-if="section === 'color'"
      >
        <!-- Plain -->
        <b-form-group
          label="Plain"
          label-for="plain"
        >
          <b-form-select
            v-model="materialData.color_details.plain"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="plainOptions"
            input-id="plain"
          />
        </b-form-group>

        <!-- Printed -->
        <b-form-group
          label="Printed"
          label-for="printed"
        >
          <b-form-select
            v-model="materialData.color_details.printed"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="printedOptions"
            input-id="printed"
          />
        </b-form-group>

        <!-- Print Quality -->
        <b-form-group
          label="Print Quality"
          label-for="print-quality"
        >
          <b-form-select
            v-model="materialData.color_details.print_quality"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="printQualityOptions"
            input-id="print-quality"
          />
        </b-form-group>

        <!-- Colors -->
        <b-form-group
          label="Colors"
          label-for="colors"
        >
          <v-select
            v-model="materialData.colors"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="colorOptions"
            :reduce="val => val.value"
            multiple
            :clearable="false"
            input-id="colors"
          >
            <template v-slot:option="option">
              <span
                :style="{backgroundColor: option.color,
                         marginRight: '5px', width: '20px', height:'20px',
                         borderRadius: '4px', display:'inline-block'}"
              /><span>{{ option.label }}</span>
            </template>
          </v-select>
        </b-form-group>
      </template>

      <template
        v-if="section === 'images'"
      >
        <!-- Image -->
        <style-media-grid-form
          :style-media="styleMedia"
          :submit-handler="submitHandler"
          :fetch-style-material-media="fetchStyleMaterialMedia"
        />
      </template>

      <template
        v-if="section === 'sourcing'"
      >
        <!-- Reference -->
        <b-form-group
          label="Ref"
          label-for="ref"
        >
          <b-form-input
            id="ref"
            v-model="materialData.ref"
            trim
          />
        </b-form-group>

        <!-- Supplier -->
        <b-form-group
          label="Supplier"
          label-for="supplier"
        >
          <b-form-select
            v-model="materialData.supplier"
            :options="supplierOptions"
            input-id="supplier"
          />
        </b-form-group>

        <!-- Price -->
        <b-form-group
          label="Price"
          label-for="price"
        >
          <b-form-input
            id="price"
            v-model="materialData.price"
            trim
            placeholder="0.00"
          />
        </b-form-group>
      </template>

      <template
        v-if="section === 'consumption'"
      >
        <!-- Quantity -->
        <b-form-group
          label="Quantity"
          label-for="quantity"
        >
          <b-form-select
            v-model="materialData.consumption.quantity"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="quantityOptions"
            input-id="quantity"
          />
        </b-form-group>
      </template>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
          :disabled="invalid"
        >
          Next
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BFormSelect,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'

import StyleMediaGridForm from './StyleMediaGridForm.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    StyleMediaGridForm,
  },
  directives: {
    Ripple,
  },
  props: {
    colorOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    supplierOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    section: {
      type: String,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },
    fetchStyleMaterialMedia: {
      type: Function,
      required: false,
      default: null,
    },
    styleMedia: {
      type: Array,
      required: false,
      default: () => [],
    },
    initData: {
      type: Object,
      required: false,
      default: () => ({
        specifics: {}, dimensions: {}, consumption: {}, color_details: {},
      }),
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props) {
    const qualityOptions = [
      'Carton FSC',
      'Hard Paper FSC',
      'Other',
    ]
    const standardOptions = [
      '',
      '240x180x50',
      '300x240x100',
      '215x155x50',
      '250x200x100',
      '430x300x1800',
    ]
    const dimensionOptions = [
      'mm',
      'cm',
    ]
    const plainOptions = [
      'Natural',
      'White',
    ]
    const printedOptions = [
      'All over',
      'Placement Print',
    ]
    const printQualityOptions = [
      'Standard',
      'Embossed',
      'Debossed',
      '3D Gloss',
    ]
    const quantityOptions = [
      '<100',
      '<300',
      '<500',
      '>500',
    ]

    const materialDataInit = {
      specifics: {},
      consumption: {},
      dimensions: {},
      color_details: {},
    }
    const materialData = ref({ ...materialDataInit })

    const resetMaterialData = () => {
      materialData.value = { ...materialDataInit }
    }

    watch(() => props.initData, newValue => {
      if (newValue !== undefined) {
        materialData.value = _.cloneDeep(newValue)
      }
    }, { deep: true, immediate: true })

    const onSubmit = () => {
      const { section } = props
      let formData = {}

      switch (section) {
        case 'specifics':
        case 'dimensions':
        case 'consumption':
        case 'placement':
          formData = { [section]: materialData.value[section] }
          break
        case 'color':
          formData = {
            colors: materialData.value.colors,
            color_details: materialData.value.color_details,
          }
          break
        case 'sourcing':
          formData = {
            ref: materialData.value.ref,
            supplier: materialData.value.supplier,
            price: materialData.value.price,
          }
          break
        default:
          formData = {}
      }
      props.submitHandler({
        id: materialData.value.id || router.currentRoute.params.id,
        category_type: 'Box',
        ...formData,
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMaterialData)

    return {
      qualityOptions,
      standardOptions,
      dimensionOptions,
      plainOptions,
      printedOptions,
      printQualityOptions,
      quantityOptions,
      materialData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
