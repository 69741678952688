<template>
  <section class="materials-edit-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="styleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching style data
      </h4>
      <div class="alert-body">
        No style found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-styles-list'}"
        >
          Styles
        </b-link>
        list.
      </div>
    </b-alert>

    <b-row v-if="styleData">

      <b-col
        cols="12"
      >
        <b-card>
          <div class="d-flex mt-1 mb-1 justify-content-start">
            <div class="mr-5">
              <b-media
                left-align
                vertical-align="center"
              >
                <template #aside>
                  <b-img
                    v-if="styleData.cover_image"
                    rounded
                    height="64"
                    :src="styleData.cover_image"
                  />
                </template>
                <h4 class="card-title mt-0 mb-1">
                  {{ styleData.name }}
                </h4>
                <h6 class="card-subtitle text-muted">
                  {{ styleData.ref }}
                </h6>
              </b-media>
            </div>
            <div class="mr-5">
              <h5 class="mt-0 mb-1 text-muted">
                Category
              </h5>
              <h6 class="card-subtitle ">
                {{ categoryText(styleData.category) }}
              </h6>
            </div>
            <div class="mr-5">
              <h5 class="mt-0 mb-1 text-muted">
                Brand
              </h5>
              <h6 class="card-subtitle ">
                {{ styleData.brand_name }}
              </h6>
            </div>
            <div>
              <h5 class="mt-0 mb-1 text-muted">
                Status
              </h5>
              <h6 class="card-subtitle ">
                {{ styleData.status }}
              </h6>
            </div>
          </div>
        </b-card>

        <div class="d-flex align-items-center mb-2">
          <b-button
            variant="outline-primary"
            size="md"
            class="mr-2"
            @click="goBack"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="16"
              class="mr-2"
            />Back
          </b-button>
          <h4 class="mb-0">
            {{ headerTitle }}
          </h4>
        </div>

        <div
          class="accordion"
          role="tablist"
        >
          <b-card
            v-if="availableSections.includes('specifics')"
            no-body
            class="mb-1 card-overflow-visible"
          >
            <b-card-header
              header-tag="header"
              class="p-1 "
              role="tab"
            >
              <b-button
                block
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'specifics'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('specifics') }}
                </b-badge>
                Specifics characteristics
              </b-button>
            </b-card-header>
            <b-collapse
              id="specifics"
              :visible="activeSection === 'specifics'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :section="'specifics'"
                    :init-data="initData"
                    :submit-handler="onSubmit"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('dimensions') && !isFabric"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="dimensionsDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'dimensions'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('dimensions') }}
                </b-badge>
                Dimensions
              </b-button>
            </b-card-header>
            <b-collapse
              id="dimensions"
              :visible="activeSection === 'dimensions'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :section="'dimensions'"
                    :init-data="initData"
                    :submit-handler="onSubmit"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('color')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="colorDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'color'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('color') }}
                </b-badge>
                Color
              </b-button>
            </b-card-header>
            <b-collapse
              id="color"
              :visible="activeSection === 'color'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :section="'color'"
                    :init-data="initData"
                    :color-options="colorOptions"
                    :submit-handler="onSubmit"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('images')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="imagesDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'images'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('images') }}
                </b-badge>
                Images and Artwork
              </b-button>
            </b-card-header>
            <b-collapse
              id="images"
              :visible="activeSection === 'images'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :section="'images'"
                    :init-data="initData"
                    :style-media="styleMedia"
                    :submit-handler="filesUpload"
                    :fetch-style-material-media="fetchStyleMaterialMedia"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('sourcing')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="sourcingDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'sourcing'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('sourcing') }}
                </b-badge>
                Sourcing Details
              </b-button>
            </b-card-header>
            <b-collapse
              id="sourcing"
              :visible="activeSection === 'sourcing'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :supplier-options="supplierOptions"
                    :section="'sourcing'"
                    :init-data="initData"
                    :submit-handler="onSubmit"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('consumption')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="consumptionDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'consumption'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('consumption') }}
                </b-badge>
                Consumption
              </b-button>
            </b-card-header>
            <b-collapse
              id="consumption"
              :visible="activeSection === 'consumption'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :section="'consumption'"
                    :init-data="initData"
                    :submit-handler="onSubmit"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card
            v-if="availableSections.includes('placement')"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                block
                :disabled="placementDisabled"
                variant="outline-primary"
                class="d-flex justify-content-start align-items-center"
                @click="activeSection = 'placement'"
              >
                <b-badge variant="dark mr-1">
                  {{ sectionNumber('placement') }}
                </b-badge>
                Placement
              </b-button>
            </b-card-header>
            <b-collapse
              id="placement"
              :visible="activeSection === 'placement'"
              accordion="materials"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <material-form
                    :category-type="categoryType"
                    :section="'placement'"
                    :init-data="initData"
                    :submit-handler="onSubmit"
                  />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          v-if="activeSection === ''"
          class="d-flex align-items-center mb-2"
        >
          <b-button
            variant="primary"
            size="md"
            class="mr-2"
            @click="goBack"
          >
            Save
          </b-button>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert, BBadge, BButton, BCard, BCardBody, BCardHeader, BCardText, BCol, BCollapse,
  BImg, BLink, BMedia, BRow,
} from 'bootstrap-vue'
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import styleStoreModule from '../styleStoreModule'
import MaterialForm from './MaterialForm.vue'

export default {
  components: {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCol,
    BCollapse,
    BImg,
    BLink,
    BMedia,
    BRow,

    MaterialForm,
  },
  setup(props, { root }) {
    const toast = useToast()

    const categoryTitles = category => {
      const categoryType = category.replace('-', ' ')
      return categoryType.toLowerCase().split(' ')
        .map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
    }

    const categoryType = ref(router.currentRoute.params.category)
    const isNew = router.currentRoute.query.new === '1'
    const isAdd = router.currentRoute.name === 'apps-styles-add-material'
    const isEdit = !isAdd

    const activeSection = ref(isAdd ? 'specifics' : 'dimensions')
    const dimensionsDisabled = ref(isAdd || isNew)
    const colorDisabled = ref(isAdd || isNew)
    const imagesDisabled = ref(isAdd || isNew)
    const sourcingDisabled = ref(isAdd || isNew)
    const consumptionDisabled = ref(isAdd || isNew)
    const placementDisabled = ref(isAdd || isNew)

    const styleData = ref(null)
    const styleMedia = ref([])
    const initData = ref({ specifics: {}, dimensions: {}, consumption: {} })

    const userRole = JSON.parse(localStorage.getItem('userData')).role

    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const categoryOptions = computed(() => {
      const options = root.$store.getters['app-style/categoryOptions']
      return options
    })
    const categoryText = computed(() => category => {
      const found = Array.from(categoryOptions.value)
        .find(option => option.value === category)
      return found ? found.label : category
    })
    const colorOptions = computed(() => {
      const options = root.$store.getters['app-style/colorOptions']
      return options
    })
    const supplierOptions = computed(() => {
      const options = root.$store.getters['app-style/supplierOptions']
      return options
    })

    const fabricCategoryTypes = [
      'main-fabric',
      'second-fabric',
      'trims-fabric',
      'lining',
      'rib',
    ]
    const isFabric = computed(() => fabricCategoryTypes.includes(categoryType.value))

    const categoryTitle = computed(() => categoryTitles(categoryType.value))

    const headerTitle = computed(() => (
      `${isAdd ? 'New' : 'Edit'} ${categoryTitle.value}`
    ))

    const availableSections = computed(() => {
      let sections = []

      if (isFabric.value) {
        sections = [
          'specifics', 'color', 'images', 'sourcing', 'consumption', 'placement']
      } else if (categoryType.value === 'box') {
        sections = [
          'specifics', 'dimensions', 'color', 'images', 'sourcing', 'consumption']
      } else {
        sections = [
          'specifics', 'dimensions', 'color', 'images', 'sourcing', 'consumption', 'placement']
      }

      if (userRole === 'brand' || userRole === 'brand-manager') {
        sections = sections.filter(item => !['sourcing', 'consumption'].includes(item))
      }
      return sections
    })

    const sectionNumber = computed(() => section => (
      availableSections.value.indexOf(section) + 1))

    const optionsInit = () => {
      root.$store.dispatch('app-style/fetchFormOptions')
    }

    const fetchStyle = () => {
      store.dispatch('app-style/fetchStyle', { id: router.currentRoute.params.id })
        .then(response => {
          styleData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleData.value = undefined
          }
        })
    }

    const fetchMaterial = () => {
      const materialId = router.currentRoute.params.material
      if (!materialId) {
        return
      }
      store.dispatch('app-style/fetchStyleMaterial', { id: materialId })
        .then(response => {
          initData.value = { ...response.data }
          if (initData.value.category_type) {
            categoryType.value = initData.value.category_type
              .toLowerCase().replace(' ', '-')

            if (isNew) {
              if (isFabric.value) {
                activeSection.value = 'color'
                colorDisabled.value = false
              } else {
                activeSection.value = 'dimensions'
                dimensionsDisabled.value = false
              }
            } else {
              activeSection.value = 'specifics'
            }
          }
        })
    }

    const fetchStyleMaterialMedia = () => {
      const id = router.currentRoute.params.material
      if (!id) {
        return
      }
      store.dispatch('app-style/fetchStyleMaterialMedia', { id })
        .then(response => {
          styleMedia.value = response.data.style_material_media
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleMedia.value = []
          }
        })
    }

    const goBack = () => {
      router.go(-1)
    }

    const openNextSection = () => {
      const totalAvailableSections = availableSections.value.length
      const sectionIndex = availableSections.value.indexOf(activeSection.value)
      if (sectionIndex + 1 < totalAvailableSections) {
        activeSection.value = availableSections.value[sectionIndex + 1]
      } else {
        activeSection.value = ''
      }
      if (activeSection.value === 'dimensions') {
        dimensionsDisabled.value = false
      } else if (activeSection.value === 'color') {
        colorDisabled.value = false
      } else if (activeSection.value === 'images') {
        imagesDisabled.value = false
      } else if (activeSection.value === 'sourcing') {
        sourcingDisabled.value = false
      } else if (activeSection.value === 'consumption') {
        consumptionDisabled.value = false
      } else if (activeSection.value === 'placement') {
        placementDisabled.value = false
      }
    }

    const materialCreated = response => {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${categoryTitle.value} created`,
          icon: 'InfoIcon',
          variant: 'success',
        },
      })
      router.push({
        name: 'apps-styles-edit-material',
        params: {
          id: router.currentRoute.params.id,
          material: response.data.id,
        },
        query: {
          new: '1',
        },
      })
      openNextSection()
    }

    const materialUpdated = dontOpenNext => {
      const sectionTitles = {
        specifics: 'Specifics characteristics',
        dimensions: 'Dimensions',
        color: 'Color',
        images: 'Images and Artwork',
        sourcing: 'Sourcing Details',
        consumption: 'Consumption',
        placement: 'Placement',
      }

      let title = ''
      if (activeSection.value === 'placement') {
        title = `${categoryTitle.value} Saved`
      } else {
        title = `${sectionTitles[activeSection.value]} Updated`
      }
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'success',
        },
      })
      if (!dontOpenNext) {
        openNextSection()
      }
    }

    const filesUpload = files => {
      if (files.value) {
        const styleMediaUpload = new FormData()
        styleMediaUpload.append('tag', categoryTitle.value)
        files.value.forEach(upload => {
          styleMediaUpload.append('files', upload)
        })
        store.dispatch('app-style/addStyleMaterialMedia', {
          id: router.currentRoute.params.material,
          styleMediaUpload,
        })
          .then(() => {
            fetchStyleMaterialMedia()
            materialUpdated(true)
          })
      } else {
        openNextSection()
      }
    }

    const onSubmit = formData => {
      if (isEdit) {
        store.dispatch('app-style/updateStyleMaterial', formData)
          .then(() => {
            materialUpdated()
          })
      } else {
        store.dispatch('app-style/addStyleMaterial', formData)
          .then(response => {
            materialCreated(response)
          })
      }
    }

    onMounted(optionsInit)
    onMounted(fetchStyle)
    onMounted(fetchMaterial)
    onMounted(fetchStyleMaterialMedia)

    return {
      isNew,
      categoryType,
      isFabric,
      activeSection,
      dimensionsDisabled,
      colorDisabled,
      imagesDisabled,
      sourcingDisabled,
      consumptionDisabled,
      placementDisabled,

      styleData,
      styleMedia,
      initData,
      userRole,
      colorOptions,
      supplierOptions,
      categoryText,
      categoryTitles,
      headerTitle,
      availableSections,
      sectionNumber,
      fetchStyle,
      goBack,
      openNextSection,
      onSubmit,
      filesUpload,
      fetchStyleMaterialMedia,
    }
  },
}
</script>

<style lang="scss">
label {
  font-weight: 600;
}
.card-overflow-visible {
  overflow: visible!important;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
