<template>

  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >

      <template
        v-if="section === 'specifics'"
      >
        <!-- Quality -->
        <validation-provider
          #default="validationContext"
          name="Qualtiy"
          rules="required"
        >
          <b-form-group
            label="Quality *"
            label-for="quality"
            :state="getValidationState(validationContext)"
          >
            <b-form-select
              v-model="materialData.specifics.quality"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="qualityOptions"
              input-id="quality"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

      </template>

      <template
        v-if="section === 'dimensions'"
      >
        <!-- Width -->
        <b-form-group
          label="Width"
          label-for="width"
        >
          <b-form-select
            v-model="materialData.dimensions.width"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="widthOptions"
            input-id="width"
          />
        </b-form-group>

      </template>

      <template
        v-if="section === 'color'"
      >
        <!-- Color -->
        <b-form-group
          label="Color"
          label-for="color"
        >
          <b-form-select
            v-model="materialData.raw_color"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="colorOptions"
            input-id="color"
          />
        </b-form-group>
      </template>

      <template
        v-if="section === 'images'"
      >
        <!-- Image -->
        <style-media-grid-form
          :style-media="styleMedia"
          :submit-handler="submitHandler"
          :fetch-style-material-media="fetchStyleMaterialMedia"
        />
      </template>

      <template
        v-if="section === 'sourcing'"
      >
        <!-- Reference -->
        <b-form-group
          label="Ref"
          label-for="ref"
        >
          <b-form-input
            id="ref"
            v-model="materialData.ref"
            trim
          />
        </b-form-group>

        <!-- Supplier -->
        <b-form-group
          label="Supplier"
          label-for="supplier"
        >
          <b-form-select
            v-model="materialData.supplier"
            :options="supplierOptions"
            input-id="supplier"
          />
        </b-form-group>

        <!-- Price -->
        <b-form-group
          label="Price"
          label-for="price"
        >
          <b-form-input
            id="price"
            v-model="materialData.price"
            trim
            placeholder="0.00"
          />
        </b-form-group>
      </template>

      <template
        v-if="section === 'consumption'"
      >
        <!-- Quantity -->
        <validation-provider
          #default="validationContext"
          name="Quantity"
        >
          <b-form-group
            label="Quantity"
            label-for="quantity"
          >
            <b-input-group>
              <b-form-input
                id="quantity"
                v-model="materialData.consumption.quantity"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-input-group-append>
                <b-form-select
                  v-model="materialData.consumption.quantity_units"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="quantityUnitsOptions"
                  input-id="quantity-units"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Length Sample Size -->
        <b-form-group
          label="Length Sample Size"
          label-for="length-sample-size"
        >
          <b-input-group>
            <b-form-input
              id="length-sample-size"
              v-model="materialData.consumption.length_sample_size"
              trim
            />
            <b-input-group-append>
              <b-form-select
                v-model="materialData.consumption.length_sample_size_units"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lengthSampleSizeUnitsOptions"
                input-id="length-sample-size-units"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </template>

      <template
        v-if="section === 'placement'"
      >
        <!-- Placement -->
        <b-form-group
          label="Placement"
          label-for="placement"
        >
          <b-form-textarea
            id="placement"
            v-model="materialData.placement"
            trim
            rows="3"
            max-rows="5"
          />
        </b-form-group>
      </template>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
          :disabled="invalid"
        >
          Next
        </b-button>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BFormSelect, BFormTextarea,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

import StyleMediaGridForm from './StyleMediaGridForm.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    StyleMediaGridForm,
  },
  directives: {
    Ripple,
  },
  props: {
    supplierOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    section: {
      type: String,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: false,
      default: null,
    },
    fetchStyleMaterialMedia: {
      type: Function,
      required: false,
      default: null,
    },
    styleMedia: {
      type: Array,
      required: false,
      default: () => [],
    },
    initData: {
      type: Object,
      required: false,
      default: () => ({
        specifics: {}, dimensions: {}, consumption: {},
      }),
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props) {
    const qualityOptions = [
      'Regular',
      'Soft',
      'Recycled',
      'Round',
      'Customized',
      'Other',
    ]
    const widthOptions = [
    ]
    for (let width = 1; width <= 7; width += 0.5) {
      widthOptions.push(`${width} cm`)
    }
    const colorOptions = [
      'Black',
      'White',
    ]
    const quantityUnitsOptions = [
      'per garment',
    ]
    const lengthSampleSizeUnitsOptions = [
      'mm',
      'cm',
    ]

    const materialDataInit = {
      specifics: {},
      consumption: {},
      dimensions: {},
    }
    const materialData = ref({ ...materialDataInit })

    const resetMaterialData = () => {
      materialData.value = { ...materialDataInit }
    }

    watch(() => props.initData, newValue => {
      if (newValue !== undefined) {
        materialData.value = _.cloneDeep(newValue)
      }
    }, { deep: true, immediate: true })

    const onSubmit = () => {
      const { section } = props
      let formData = {}

      switch (section) {
        case 'specifics':
        case 'dimensions':
        case 'consumption':
        case 'placement':
          formData = { [section]: materialData.value[section] }
          break
        case 'color':
          formData = { raw_color: materialData.value.raw_color }
          break
        case 'sourcing':
          formData = {
            ref: materialData.value.ref,
            supplier: materialData.value.supplier,
            price: materialData.value.price,
          }
          break
        default:
          formData = {}
      }
      props.submitHandler({
        id: materialData.value.id || router.currentRoute.params.id,
        category_type: 'Elastic',
        ...formData,
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetMaterialData)

    return {
      qualityOptions,
      widthOptions,
      colorOptions,
      quantityUnitsOptions,
      lengthSampleSizeUnitsOptions,

      materialData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
